@font-face {
  font-family: "GT Pressura Variable";
  src: url(../public/fonts/GTPressuraVariable/GT-Pressura-VF.woff2)
    format("woff2-variations");
  src: url(../public/fonts/GTPressuraVariable/GT-Pressura-VF.woff2)
    format("woff2") tech("variations");
  font-weight: 100 1000;
  ascent-override: 92%;
  descent-override: 15%;
}

@font-face {
  font-family: "GT Pressura Mono Variable";
  src: url(../public/fonts/GTPressuraVariable/GT-Pressura-Mono-VF.woff2)
    format("woff2-variations");
  src: url(../public/fonts/GTPressuraVariable/GT-Pressura-Mono-VF.woff2)
    format("woff2") tech("variations");
  font-weight: 100 1000;
  ascent-override: 92%;
  descent-override: 15%;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url(../public/fonts/IBMPlexMono/IBMPlexMono-Medium.woff2) format("woff2");
  font-weight: 100 500;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url(../public/fonts/IBMPlexMono/IBMPlexMono-Bold.woff2) format("woff2");
  font-weight: 501 1000;
}

:root {
  color-scheme: dark;
}

html,
body {
  overflow: hidden;
  overscroll-behavior: none;
  -ms-overflow-style: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ol {
  margin: 0;
  padding-inline-start: 20px;
}

.padre-no-scroll::-webkit-scrollbar {
  display: none;
}

.padre-no-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.padre-custom-scroll::-webkit-scrollbar {
  width: 20px;
}
.padre-custom-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.padre-custom-scroll::-webkit-scrollbar-thumb {
  background: #181c20; /* background.default */
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.padre-custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #1f232a; /* background.dividerLight */
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
